/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import "~swiper/swiper-bundle.css";

/******************************************************************************/
/*  文字顏色的 class */
/******************************************************************************/
.Primary-normal {
  color: var(--ion-color-primary);
}
.Primary-shade {
  color: var(--ion-color-primary-shade);
}
.Primary-tint {
  color: var(--ion-color-primary-tint);
}

.Secondary-normal {
  color: var(--ion-color-secondary);
}
.Secondary-shade {
  color: var(--ion-color-secondary-shade);
}
.Secondary-tint {
  color: var(--ion-color-secondary-tint);
}

.Tertiary-normal {
  color: var(--ion-color-tertiary);
}
.Tertiary-shade {
  color: var(--ion-color-tertiary-shade);
}
.Tertiary-tint {
  color: var(--ion-color-tertiary-tint);
}

.Warning-normal {
  color: var(--ion-color-warning);
}
.Warning-shade {
  color: var(--ion-color-warning-shade);
}
.Warning-tint {
  color: var(--ion-color-warning-tint);
}

.Danger-normal {
  color: var(--ion-color-danger);
}
.Danger-shade {
  color: var(--ion-color-danger-shade);
}
.Danger-tint {
  color: var(--ion-color-danger-tint);
}

.Dark-normal {
  color: var(--ion-color-dark);
}
.Dark-shade {
  color: var(--ion-color-dark-shade);
}
.Dark-tint {
  color: var(--ion-color-dark-tint);
}

.Medium-normal {
  color: var(--ion-color-medium);
}
.Medium-shade {
  color: var(--ion-color-medium-shade);
}
.Medium-tint {
  color: var(--ion-color-medium-tint);
}

.Light-normal {
  color: var(--ion-color-light);
}
.Light-shade {
  color: var(--ion-color-light-shade);
}
.Light-tint {
  color: var(--ion-color-light-tint);
}
.White {
  color: white;
}
.Stroke-White {
  stroke: white;
}
.Stroke-Dark-normal {
  stroke: var(--ion-color-dark);
}

/* 一行文字過長截斷,儘量為乾淨的div,不要再有padding or margin,否則會有問題 */
.text-ellipsis-1-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  // font-size: 1rem;
  // line-height: 1.5rem;
  // height: 1.5rem;
}

.Overline.text-ellipsis-1-lines {
  line-height: calc(12 / var(--base-width) * 100vw);
}

/* 兩行文字過長截斷,儘量為乾淨的div,不要再有padding or margin,否則會有問題 */
.text-ellipsis-2-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  // font-size: 1rem;
  // line-height: 1.5rem;
  // height: 3.5rem;
}

/* 三行文字過長截斷,儘量為乾淨的div,不要再有padding or margin,否則會有問題 */
.text-ellipsis-3-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* 四行文字過長截斷,儘量為乾淨的div,不要再有padding or margin,否則會有問題 */
.text-ellipsis-4-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* flex 相關的 class */
.myflex {
  display: flex;
}

.flex-jc-center {
  justify-content: center;
}
.flex-jc-start {
  justify-content: flex-start;
}
.flex-jc-end {
  justify-content: flex-end;
}
.flex-jc-between {
  justify-content: space-between;
}
.flex-ai-center {
  align-items: center;
}
.flex-ai-start {
  align-items: flex-start;
}
.flex-ai-end {
  align-items: flex-end;
}
.flex-as-center {
  align-self: center;
}
.flex-direction-column {
  flex-direction: column;
}
.flex1 {
  flex: 1;
}

.h-56px {
  height: 56px;
}

.box-s {
  box-shadow: 0px 0px 2px rgba(162, 146, 193, 1);
}

/* header */
.myheader-bg-light {
  ion-toolbar {
    --background: var(--ion-color-light-tint);
    // height: 56px;
    display: flex;
  }
  ion-buttons {
    height: 56px;
  }
}

.myheader-bg-white {
  ion-toolbar {
    --background: white;
    //padding-top: var(--ion-safe-area-top, 0);
    // height: 56px;
    display: flex;
  }
  ion-buttons {
    height: 56px;
  }
}

.myheader-bg-primary-tine {
  ion-toolbar {
    --background: var(--ion-color-primary-tint);
    //padding-top: var(--ion-safe-area-top, 0);
    // height: 56px;
    display: flex;
  }
  ion-buttons {
    height: 56px;
  }
}

.myheader-bg-primary-tine2 {
  ion-toolbar {
    --background: var(--ion-color-primary-tint);
    height: 56px;
    display: flex;
  }
  ion-buttons {
    height: 56px;
  }
}

.myheader-bg-primary {
  ion-toolbar {
    --background: var(--ion-color-primary);
    display: flex;
  }
  ion-buttons {
    height: 56px;
  }
}

ion-back-button {
  //--icon-padding-start: 10px;
}

ion-back-button::part(icon) {
  // width: 24px;
  // height: 24px;
}

/* 分隔線 */
.divider {
  // width: calc(323 / var(--base-width) * 100vw);
  opacity: 0.7;
  // border: 1px dashed var(--ion-color-tertiary); // 這個會看起來有兩條線 top and bottom
  border-top: 1px;
  border-top-style: dashed;
  border-top-color: var(--ion-color-tertiary);
  //margin: auto;
}

.divider-box-shadow {
  box-shadow: inset 0px 2px 2px rgba(162, 146, 193, 0.1);
  height: 2px;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

/* 預設將ion-grid ion-col padding 去掉 */
ion-grid {
  padding: unset;
}

ion-col {
  padding: unset;
}

/* 關注醫師的modal */
.watch-expert-modal {
  --height: calc(200 * var(--width-ratio)) !important;
  //--width: calc(300 * var(--width-ratio)) !important;
  --background: transparent !important;

  ::part(background) {
    // background: yellow;
    // border-radius: 30px;
    background: transparent;
  }
}

/* 提示登入的modal */
.login-popup-modal {
  --height: calc(300 * var(--width-ratio)) !important;
  --width: calc(280 * var(--width-ratio)) !important;
  --background: transparent !important;

  ::part(background) {
    // background: yellow;
    border-radius: 10px;
    // background: transparent;
  }
}

/* 讓modal 寬度都100% , ipad 版型才不會跑版 */
.my-custom-modal-class {
  --width: 100%;
}

.my-custom-modal-class2 {
  --width: 100%;
  --height: 100%;
}

/* 編輯寶寶modal */
.MemberBabyEditModal-class {
  --height: calc(350 * var(--width-ratio));
}

/* 日期picker modal */
.datetime-modal-class {
  --height: calc(350 * var(--width-ratio)) !important;
  --background: transparent !important;

  ::part(background) {
    background: transparent;
  }

  ion-datetime {
    margin: auto;
    border-radius: 10px;
    background: white;
  }
}

.my-custom-alert-class {
  .alert-message {
    color: var(--ion-color-dark-tint);
  }
}

.my-custom-alert-class .alert-wrapper {
  color: var(--ion-color-dark-tint);

  .alert-message {
    /* H5_M */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: calc(18 / var(--base-width) * 100vw);
    line-height: calc(26 / var(--base-width) * 100vw);
  }
}

/* 刪除帳號的alert dialog 樣式  */
.my-custom-delete-account-alert .alert-wrapper {
  .alert-head h2 {
    color: var(--ion-color-dark-tint);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: calc(15 / var(--base-width) * 100vw);
    line-height: calc(22 / var(--base-width) * 100vw);
    white-space: pre-line;
  }

  .alert-button-group {
    .alert-button {
      width: 100%;
      border-radius: 30px;
      margin-bottom: 16px;
      margin-left: 16px;
      margin-right: 16px;
    }

    .alert-button.alert-button-confirm {
      background-color: var(--ion-color-danger);
      color: var(--ion-color-danger-contrast);
    }

    .alert-button:last-child {
      border: 1px;
      border-color: var(--ion-color-primary);
      border-style: solid;
    }
  }
}

/* mommy專案的alert dialog 樣式  */
.mommy-alert-class .alert-wrapper {
  --max-width: calc(300 * var(--width-ratio));

  .alert-head h2 {
    color: var(--ion-color-dark-tint);
    /* Subtitle1_B */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: calc(15 / var(--base-width) * 100vw);
    line-height: calc(22 / var(--base-width) * 100vw);
    white-space: pre-line;
  }

  .alert-message {
    color: var(--ion-color-dark-tint);
    /* Subtitle1_M */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: calc(15 / var(--base-width) * 100vw);
    line-height: calc(22 / var(--base-width) * 100vw);
  }

  .alert-button-group {
    .alert-button {
      width: 100%;
      border-radius: 30px;
      margin-bottom: 16px;
      margin-left: 16px;
      margin-right: 16px;
    }

    .alert-button.alert-button-confirm {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    }

    .alert-button:last-child {
      border: 1px;
      border-color: var(--ion-color-primary);
      border-style: solid;
    }
  }
}

/* 檢舉留言的 alert dialog 樣式 */
.my-report-alert-class .alert-wrapper {
  --max-width: calc(300 * var(--width-ratio));
  width: calc(280 * var(--width-ratio));

  .alert-radio-group.sc-ion-alert-md {
    border-top: 0;
    border-bottom: 0;
    max-height: calc(384 * var(--width-ratio));
  }

  .alert-head h2 {
    color: var(--ion-color-dark-tint);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: calc(15 / var(--base-width) * 100vw);
    line-height: calc(22 / var(--base-width) * 100vw);
    white-space: pre-line;
    text-align: center;
  }

  .my-report-radio-class {
    .my-report-radio-class {
      width: calc(14 * var(--width-ratio));
      height: calc(14 * var(--width-ratio));
    }
    .alert-radio-label.sc-ion-alert-md {
      color: var(--ion-color-dark-tint);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: calc(15 * var(--width-ratio));
      line-height: calc(22 * var(--width-ratio));
      padding-left: calc(40 * var(--width-ratio));
    }
  }

  .alert-button-group {
    .alert-button {
      width: calc(118 * var(--width-ratio));
      border-radius: 30px;
      margin-bottom: 16px;

      .alert-button-inner.sc-ion-alert-md {
        justify-content: center;
      }
    }

    .alert-button.alert-button-report {
      background-color: var(--ion-color-danger-tint);
      color: var(--ion-color-danger-contrast);
    }

    .alert-button.alert-button-cancel {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    }
  }
}

/* EC提醒輸入 email alert dialog 樣式 */
.mommy-email-alert-class .alert-wrapper {
  --max-width: calc(300 * var(--width-ratio));
  width: calc(280 * var(--width-ratio));

  .alert-head h2 {
    color: var(--ion-color-dark-tint);
    /* H5_M */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: calc(18 / var(--base-width) * 100vw);
    line-height: calc(26 / var(--base-width) * 100vw);
    white-space: pre-line;
  }

  .alert-message {
    color: var(--ion-color-dark-tint);
    /* Subtitle1_M */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: calc(15 / var(--base-width) * 100vw);
    line-height: calc(22 / var(--base-width) * 100vw);

    span {
      color: var(--ion-color-danger);
    }
  }

  .alert-button-group {
    .alert-button {
      width: 100%;
      border-radius: 30px;
      margin-bottom: 16px;
      margin-left: 16px;
      margin-right: 16px;
    }

    .alert-button.alert-button-confirm {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    }

    .alert-button:last-child {
      border: 1px;
      border-color: var(--ion-color-primary);
      border-style: solid;
    }
  }
}

/* image modal */
.transparent-modal {
  --background: black;
  // --background: rgba(44, 39, 45, 0.2);

  // &::part(content) {
  //   backdrop-filter: blur(12px);
  // }
}

// 影片任務使用的 css class
.task-toast {
  --background: rgba(var(--ion-color-dark-rgb), 0.8);
  --color: white;
}

ion-toast::part(message) {
  text-align: center;
}

// EC購物車的 badge
.cart-badge-one {
  position: absolute;
  top: 0px;
  right: -5px;
  font-size: 0.6em;
  --padding-start: 5px;
  --padding-end: 5px;
}

.cart-badge-two {
  position: absolute;
  top: 0px;
  right: -5px;
  font-size: 0.6em;
  --padding-start: 3px;
  --padding-end: 3px;
}

/* 將ion-toast message 長的字串可以換行 */
ion-toast::part(message) {
  overflow-wrap: anywhere;
}

.alert-color {
  color: #ffb315;
}

/* for qrcode scanner */
body.scanner-active {
  // --background: transparent;
  --ion-background-color: transparent;
  // opacity: 0;
  ion-router-outlet {
    opacity: 0;
  }
}

/* 能不能吃 能吃的標籤色票 */
.Green-normal {
  color: #8bc727;
}

/* ion-select interface=popover , 調整 padding */
.custom-popover ion-select-popover ion-item {
  ion-label {
    padding-left: 16px;
  }
}

.opacity5 {
  opacity: 0.5;
}

// for noscript tag
html:not(.hydrated) body {
  display: initial !important;
}
